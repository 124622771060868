import React from 'react';
import { Paper } from '@material-ui/core';

interface MapOverlayProps {
  width?: string | number;
  height?: string | number;
  top?: string | number;
  left?: string | number;
  overflow?: string;
  showBackground?: boolean;
  opacity?: number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const MapOverlay: React.FunctionComponent<MapOverlayProps> = ({
  width = 'auto',
  height = 'auto',
  top = '0%',
  left = '0%',
  overflow = 'hidden',
  showBackground = true,
  opacity = 1,
  style = {},
  className,
  children,
}) => {
  return (
    <Paper
      style={{
        position: 'absolute',
        width,
        height,
        left,
        top,
        overflow,
        display: showBackground ? 'block' : 'contents',
        backgroundColor: `rgba(255,255,255,${opacity})`,
        ...style,
      }}
      className={className}
    >
      {children}
    </Paper>
  );
};

MapOverlay.displayName = 'MapOverlay';
export default MapOverlay;
