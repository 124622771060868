import { getTopLeft, getWidth } from 'ol/extent';
import { get as getProjection } from 'ol/proj';
import TileGrid from 'ol/tilegrid/TileGrid';

import { Common, AuthManager, Loadable } from 'models';
import * as MapLayerManager from 'models/maplayer';
import * as LocationManager from 'models/location';
import config from 'config';

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const ratingToName = (rating: number | null, na = ''): string => {
  if (rating === 0) return 'No Rating';
  if (rating === 1) return 'Moderate';
  if (rating === 2) return 'High';
  if (rating === 3) return 'Extreme';
  if (rating === 4) return 'Catastrophic';

  return na;
};

export const ratingToId = (rating: number | null, na = ''): string => {
  if (rating === 0) return 'NR';
  if (rating === 1) return 'M';
  if (rating === 2) return 'H';
  if (rating === 3) return 'E';
  if (rating === 4) return 'C';

  return na;
};

export const ratingStringToAbbr = (rating: string | null, na = ''): string => {
  if (rating === 'No Rating') return 'NR';
  if (rating === 'Moderate') return 'M';
  if (rating === 'High') return 'H';
  if (rating === 'Extreme') return 'E';
  if (rating === 'Catastrophic') return 'C';

  return na;
};

export const ratingStringToNumber = (rating: string | null, na = NaN): number => {
  if (rating === 'No Rating') return 0;
  if (rating === 'Moderate') return 1;
  if (rating === 'High') return 2;
  if (rating === 'Extreme') return 3;
  if (rating === 'Catastrophic') return 4;

  return na;
};

export const hasGroup = (group: AuthManager.UserGroups, auth: Loadable<AuthManager.Auth>): boolean => {
  if (auth.object?.decoded_token?.['cognito:groups'])
    return auth.object?.decoded_token?.['cognito:groups']?.indexOf(group) > -1;
  return false;
};

export const getProperty = (path: (string | number)[], obj: Record<string | number, any>) =>
  path.reduce<any>((record, prop) => (record && record[prop] ? record[prop] : null), obj);

export const getCoordinates = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};

export const deg2rad = (deg: number) => deg * (Math.PI / 180);

export const prettifyLatLng = (coords: number[]) => {
  const [longitude, latitude] = coords;
  const latDir = latitude > 0 ? 'N' : 'S';
  const lngDir = longitude < 0 ? 'W' : 'E';

  const values = [latitude, longitude].map(Math.abs).map((x) => x.toFixed(5));

  return `${values[0]}°${latDir}, ${values[1]}°${lngDir}`;
};

export const haversineDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c * 1000; // Distance in m
  return d;
};

/**
 * Will check if the string entered by the user is valid
 * Must be a valid number and greater than or equal to 1.
 * @param input The users input to the textbox
 */
export const isValidFuelLoad = (input: string): boolean => {
  const value = +input;
  return !Number.isNaN(value) && value >= 1 && value <= 12;
};

/**
 * Will round the given number to the given number of decimal places
 * @param value The number to round
 * @param decimals The number of decimal places to round to (default: 0)
 * @returns The rounded number
 */
export const round = (value: number | null | undefined, decimals = 0): number => {
  if (value == null) return NaN;
  // Makes this very quick for cases 0, 1, 2.
  // 3 and beyond are fast but not good for anything real time or for large datasets
  switch (decimals) {
    case 0:
      return Math.round(value);
    case 1:
      return Math.round(value * 10) / 10;
    case 2:
      return Math.round(value * 100) / 100;
    default:
  }
  const pow = 10 ** decimals;
  return Math.round(value * pow) / pow;
};

/**
 * Will convert the distance in meters to a more readable string
 * eg: 1200 -> 1.2km
 * @param distance Raw distance in meters
 */
export const toReadableDistance = (distance: number | undefined | null): string | null => {
  if (distance == null) return null;
  if (distance < 0) return '0m';
  if (distance < 1000) return `${Math.round(distance)}m`;
  return `${Math.round(distance / 100) / 10}km`;
};

/**
 *
 * @param age The age in millisecionds. Negative age returns useful text, but not supported.
 */
export const toReadableAge = (age: number | undefined | null): string | null => {
  if (age == null) return null;
  if (age < 0) return "hasn't occured yet";
  if (age < 1000) return 'now';
  let time = Math.round(age / 1000);
  if (age < 60 * 1000) return `${time} second${time === 1 ? '' : 's'} ago`;
  time = Math.round(age / (1000 * 60));
  if (age < 60 * 60 * 1000) return `${time} minute${time === 1 ? '' : 's'} ago`;
  time = Math.round(age / (1000 * 60 * 60));
  if (age < 24 * 60 * 60 * 1000) return `${time} hour${time === 1 ? '' : 's'} ago`;
  time = Math.round(age / (1000 * 60 * 60 * 24));
  if (age < 7 * 24 * 60 * 60 * 1000) return `${time} day${time === 1 ? '' : 's'} ago`;
  time = Math.round(age / (1000 * 60 * 60 * 24 * 7));
  return `${time} week${time === 1 ? '' : 's'} ago`;
};

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const toDDMonFormat = (date: Date) => {
  return `${date.getDate()} ${months[date.getMonth()]}`;
};

export const toDDMonFormatAEST = (date: Date) => {
  const AESTDate = new Date(date.toLocaleString('en-US', { timeZone: 'Australia/Sydney' }));
  return `${AESTDate.getDate()} ${months[AESTDate.getMonth()]}`;
};

export const toDDMMYYYYFormat = (date: Date) => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const toTimeDDMonYearFormat = (date: Date) => {
  return `${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${
    date.getMinutes() < 10 ? '0' : ''
  }${date.getMinutes()} ${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const toTimeHHMMFormat = (date: Date) => {
  return `${String(date.getHours().toString()).padStart(2, '0')}:${String(date.getMinutes().toString()).padStart(
    2,
    '0',
  )}`;
};

export const toTimeFormatDateOrdinalMMM = (date: Date) => {
  let dayExtension = '';
  const day = date.getDate();
  if (day > 3 && day < 21) {
    dayExtension = `${day}th`;
  } else {
    switch (day % 10) {
      case 1: {
        dayExtension = `${day}st`;
        break;
      }
      case 2: {
        dayExtension = `${day}nd`;
        break;
      }
      case 3: {
        dayExtension = `${day}rd`;
        break;
      }
      default: {
        dayExtension = `${day}th`;
        break;
      }
    }
  }
  const monthText = date.toLocaleString('en-us', { month: 'short' });
  return `${dayExtension} ${monthText}`;
};

interface ColourInterpInterface {
  colour1Hex: string;
  colour2Hex: string;
  colour1Quantity: number;
  colour2Quantity: number;
  interpColourQuantity: number;
}

export const colourInterpHelper = ({
  colour1Hex,
  colour2Hex,
  colour1Quantity,
  colour2Quantity,
  interpColourQuantity,
}: ColourInterpInterface) => {
  const colour1RGB = parseInt(colour1Hex.slice(1), 16);
  const colour2RGB = parseInt(colour2Hex.slice(1), 16);

  const fraction = (interpColourQuantity - colour1Quantity) / (colour2Quantity - colour1Quantity);

  // Extract the R, G, and B components
  const r1 = colour1RGB >> 16;
  const g1 = (colour1RGB >> 8) & 0xff;
  const b1 = colour1RGB & 0xff;

  const r2 = colour2RGB >> 16;
  const g2 = (colour2RGB >> 8) & 0xff;
  const b2 = colour2RGB & 0xff;

  // Interpolate between the components
  const interpR = Math.round(r1 + fraction * (r2 - r1));
  const interpG = Math.round(g1 + fraction * (g2 - g1));
  const interpB = Math.round(b1 + fraction * (b2 - b1));

  // Convert the interpolated components back to hex
  const interpColour = `#${((interpR << 16) | (interpG << 8) | interpB).toString(16).padStart(6, '0')}`;
  return interpColour;
};

export const isToday = (date: Date) => new Date().toDateString() === date.toDateString();

/**
 * Will accept a user object to extract their full name from.
 * If name attributes are missing, it will return username. If all else fails, then it will return anonymous.
 * @param user The user object
 */
export const getFullName = <
  T extends {
    firstName?: string | null;
    familyName?: string | null;
    userName?: string | null;
    given_name?: string | null;
    family_name?: string | null;
    user_name?: string | null;
  },
>(
  user: T | null | undefined,
): string => {
  if (user == null) return 'Null User';
  if (user.given_name != null && user.family_name != null) return `${user.given_name} ${user.family_name}`;
  if (user.firstName != null && user.familyName != null) return `${user.firstName} ${user.familyName}`;
  if (user.user_name) return user.user_name;
  if (user.userName) return user.userName;
  return 'Anonymous';
};

export const getMimeFromDataURL = (url: string) => url.substring(url.indexOf(':') + 1, url.indexOf(';'));

export const mimeToExtension: Record<string, string> = {
  'image/png': 'png',
  'image/jpeg': 'jpg',
};

export const dataURLtoBlob = (url: string) => {
  const splitData = url.split(',');
  const byteString = atob(splitData[1]);
  const mimeString = splitData[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

export const helpLookup: Record<string, { title: string; text: Array<string> }> = {
  grassCuring: {
    title: 'Grass Curing',
    text: ['Curing is the percentage of dead material in a grassland.'],
  },
  fuelCondition: {
    title: 'Fuel Condition',
    text: [
      'Fuel condition describes the height of the grass in three categories:',
      'Natural (>50cm)',
      'Grazed (10-50cm)',
      'Eaten Out (<10cm)',
    ],
  },
  fuelLoad: {
    title: 'Fuel Load',
    text: ['Fuel load is the amount of fuel present and available to burn, measured in tonnes per hectare.'],
  },
  fuelContinuity: {
    title: 'Fuel Continuity',
    text: ['Fuel continuity is the horizontal connectedness of a grassland'],
  },
  observationPhoto: {
    title: 'Photo',
    text: ['A photo which best represents the area being observed.'],
  },
};

export const calculateFuelLoad = (fuelCondition: number, isContinuous: boolean): number => {
  if (isContinuous) {
    switch (fuelCondition) {
      case 1: // Eaten-out
        return 2;
      case 2: // Grazed
        return 3.5;
      case 3: // Natural
        return 4.5;
      default:
        return 0;
    }
  }
  switch (fuelCondition) {
    case 1:
      return 1.5;
    case 2:
      return 2.5;
    case 3:
      return 3.0;
    default:
      return 0;
  }
};

export const getFuelLoadRange = (fuelCondition: number, isContinuous: boolean): number[] => {
  if (isContinuous) {
    switch (fuelCondition) {
      case 1: // Eaten-out
        return [1.5, 2.0];
      case 2: // Grazed
        return [2.7, 4.5];
      case 3: // Natural
        return [4.0, 5.0];
      default:
        return [1.0, 12.0];
    }
  }
  switch (fuelCondition) {
    case 1:
      return [1.0, 1.5];
    case 2:
      return [2.0, 2.7];
    case 3:
      return [3.0, 4.0];
    default:
      return [1.0, 12.0];
  }
};

const australianStates = new Set<string>(['nsw', 'vic', 'qld', 'tas', 'sa', 'nt', 'wa', 'act']);
export const getUserState = (user?: AuthManager.Auth | null): Common.Jurisdictions | null => {
  const groups = user?.decoded_token?.['cognito:groups'];
  if (!groups) return null;
  for (let i = 0; i < groups.length; i += 1) {
    const group = groups[i];
    if (australianStates.has(group.toLowerCase())) return group.toLowerCase() as Common.Jurisdictions;
  }
  return null;
};

export const getGeoserverEndpoint = (service = 'wms') => {
  return `${config.geoserver_url}/${service}`;
};

export const getUserStateGeoserverEndpoint = (auth: AuthManager.Auth, service = 'wms') => {
  const state = getUserState(auth) || 'nsw';
  if (!state) return null;
  return `${config.geoserver_url}/${service}/`;
  // return `${config.geoserver_url}/fse-${state}/${service}`;
};

/**
 * Returns a tileGrid for TiledLayer
 * @param crs: The crs of the tiled layer
 * @param levels: The number of levels
 * @param tileSize: The size of each tile; assumes square tiles
 */
export const getTileGrid = (crs = 'EPSG:3857', extent: any = null, levels = 22, tileSize = 512) => {
  const resolutions = new Array(levels);
  const projection = getProjection(crs);

  // Added this assertion as later versions added null and this won't realistically be the case
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const projectionExtent = projection!.getExtent();
  const size = getWidth(projectionExtent) / tileSize;

  for (let z = 0; z < levels; z += 1) resolutions[z] = size / 2 ** z;

  const tileGridArgs = {
    minZoom: 0,
    extent: undefined,
    resolutions,
    tileSize,
    origin: getTopLeft(projectionExtent),
  };
  if (extent) tileGridArgs.extent = extent;
  return new TileGrid(tileGridArgs);
};

export const formatDifferenceValue = (mapLayer: MapLayerManager.MapLayer, value: number, units: string | undefined) => {
  switch (mapLayer?.type) {
    case 'grass-curing':
      return `${value.toFixed(0)}${units || ''}`;
    case 'grass-fuel-condition':
      return value === 0 ? 'Unchanged' : 'Changed';
    case 'grass-fuel-load':
      return `${value.toFixed(1)}${units || ''}`;
    case 'fuel-type':
      return value === 0 ? 'Unchanged' : 'Changed';
    default:
      return `${value.toString()}${units || ''}`;
  }
};

export const formatValue = (mapLayer: MapLayerManager.MapLayer, value: number, units: string | undefined) => {
  switch (mapLayer?.type) {
    case 'grass-curing':
      return `${value.toFixed(0)}${units || ''}`;
    case 'grass-fuel-condition':
      return `${LocationManager.fuelConditionText(value)}`;
    case 'grass-fuel-load':
      return `${value.toFixed(1)}${units || ''}`;
    default:
      return `${value.toString()}${units || ''}`;
  }
};

export const formatQueryString = (queryArgs: Record<string, any | any[]>): string => {
  const params = new URLSearchParams();
  Object.keys(queryArgs).forEach((k) => {
    if (Array.isArray(queryArgs[k])) {
      (queryArgs[k] as any[]).forEach((v) => params.append(k, v));
    } else if (queryArgs[k] !== undefined) {
      params.append(k, queryArgs[k]);
    }
  });
  params.sort();
  return params.toString();
};

export function hasObservations(mapLayer: MapLayerManager.MapLayer): boolean {
  switch (mapLayer?.type) {
    case 'time-since-fire':
    case 'fuel-type':
      return false;
    default:
      return true;
  }
}

export function groupBy<T>(array: T[], fn: (x: T) => string): Record<string, T[]> {
  const itemsByCategory = new Map<string, T[]>();

  for (const item of array) {
    const category = fn(item);

    if (itemsByCategory.has(category) !== undefined && itemsByCategory.get(category) instanceof Array) {
      itemsByCategory.set(category, [...(itemsByCategory.get(category) ?? []), item]);
    } else {
      itemsByCategory.set(category, [item]);
    }
  }

  return Object.fromEntries(itemsByCategory);
}
