import React, { useState, PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, Card, CardContent, Typography, Divider } from '@material-ui/core';
import { Close, SvgIconComponent, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 175,
    maxWidth: 340,
  },
  header: {
    position: 'relative',
    padding: 3,
    paddingBottom: '3px !important',
  },
  title: {
    color: theme.palette.common.neutralDark,
    fontSize: 16,
  },
  closeButton: {
    textDecoration: 'underline',
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: 3,
    fontSize: 16,
    color: theme.palette.common.neutralDark,
    '&:hover': { color: 'black' },
    outline: 0,
  },
}));

interface Props extends PropsWithChildren {
  title: string;
  contentStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

const renderIcon = (Icon: SvgIconComponent) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Icon />
  </div>
);

const OpenCloseButton: React.FunctionComponent<{ onClick: () => void; isOpen: boolean }> = ({ onClick, isOpen }) => {
  const classes = useStyles();
  return (
    <span className={classes.closeButton} onClick={onClick} role="button" onKeyDown={onClick} tabIndex={0}>
      {isOpen ? renderIcon(Close) : renderIcon(ExpandMore)}
    </span>
  );
};

const CloseableCard: React.FunctionComponent<Props> = ({ title, children, contentStyle, style }) => {
  const classes = useStyles();

  const [openValue, setOpen] = useState(false);

  return (
    <Card className={classes.root} style={style}>
      <CardContent className={classes.header}>
        <Typography className={classes.title}>{title}</Typography>
        <OpenCloseButton onClick={() => setOpen(!openValue)} isOpen={openValue} />
      </CardContent>
      {openValue && (
        <>
          <Divider />
          <CardContent style={contentStyle}>{children}</CardContent>
        </>
      )}
    </Card>
  );
};

CloseableCard.displayName = 'CloseableCard';
export default CloseableCard;
