/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Typography, Paper, Button } from '@material-ui/core';
import { Close, ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
// import { Close } from '@material-ui/icons';

import { useAppDispatch } from 'hooks';
import { FuelTypeModelsManager, LayerManager, WmsManager, LocationManager } from 'models';
import { LayerActions } from 'state/layers';
import { toTimeDDMonYearFormat, toTimeHHMMFormat, toTimeFormatDateOrdinalMMM, ratingToName } from 'utils';

import { useAppSelector } from 'hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 250,
      margin: theme.spacing(0.25),
      padding: theme.spacing(1),
      border: `0px`,
      backgroundColor: 'rgba(0,0,0,0.75)',
      display: 'grid',
      borderRadius: 4,
    },
    white: {
      color: theme.palette.common.white,
    },
    btn: {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.85)',
      },
    },
    leftRight: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '1fr auto',
    },
    subfuelDropdown: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '3px',
      paddingRight: '5px',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.85)',
      },
    },
    subFuelDescContainer: {
      width: '234px',
    },
    leftLeft: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: 'auto auto 1fr',
    },
    title: {
      color: theme.palette.common.neutralXXLight,
      fontWeight: 'bold',
      width: '100%',
    },
    subtitle: {
      color: theme.palette.common.neutralXXLight,
      fontWeight: 'bold',
      lineHeight: 'inherit',
    },
    value: {
      color: theme.palette.common.neutralXLight,
      fontWeight: 'bold',
    },
    legendSwatch: {
      width: 25,
      height: 25,
      borderRadius: 4,
      marginRight: theme.spacing(1),
    },
    legendSwatchSmall: {
      width: 15,
      height: 15,
      borderRadius: 3,
      marginTop: 3,
      marginRight: 5,
    },
  }),
);

interface WeatherPopupProps {
  close: () => void;
  onViewDetails?: (coords: number[] | null) => void;
  data: Record<string, number | string> | null;
  selectedDate?: Date | null;
  selectedLayer?: LayerManager.Layer;
  coords: number[] | null;
}

const WeatherPopup: React.FunctionComponent<WeatherPopupProps> = ({
  close,
  data,
  coords,
  onViewDetails,
  selectedLayer,
  selectedDate,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [showSubFuelDesc, setShowSubFuelDesc] = useState(false);
  const { layers, legends } = useAppSelector((state) => state.layers);
  const { bom } = useAppSelector((state) => state.fuelTypeModels);

  const layerLookup: Partial<Record<LayerManager.Layer.LayerIds, LayerManager.Layer | undefined>> = {};

  if (data)
    Object.keys(data).forEach(
      (id) => (layerLookup[id as LayerManager.Layer.LayerIds] = LayerManager.findLayer(id, layers.object)),
    );

  useEffect(() => {
    if (legends.IDZ10134_AUS_AFDRS_fdr_SFC.status === 'idle' && layers.object) {
      const fireDangerLayer = LayerManager.findLayer('IDZ10134_AUS_AFDRS_fdr_SFC', layers.object);
      if (fireDangerLayer) dispatch(LayerActions.getLegend({ layer: fireDangerLayer }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legends.IDZ10134_AUS_AFDRS_fdr_SFC.status, layers.object]);

  let selectedLayerValue = '';

  const fuelTypeModel = data?.IDZ10161_AUS_FSE_fuel_type_SFC
    ? FuelTypeModelsManager.getFuelTypeById(+data?.IDZ10161_AUS_FSE_fuel_type_SFC, bom.object)
    : null;

  const getLayerValue = (layerId: string): string | undefined => {
    // if selected layer has no time step e.g. Time Since Fire
    // just return N/A for Max Fire Danger Rating
    if (layerId === 'IDZ10136_AUS_AFDRS_max_fdr_SFC' && selectedLayer) {
      const selLyr = LayerManager.layerData.find((lyr) => lyr.id === selectedLayer.id);
      if (selLyr?.hoursPerStep === 0) {
        return 'N/A';
      }
    } else if (layerId === 'IDZ10161_AUS_FSE_fuel_type_SFC' && selectedLayer) {
      if (fuelTypeModel) {
        return fuelTypeModel.FTno_FDR.toString();
      }
    }

    if (data?.[layerId] !== undefined) {
      return data?.[layerId].toString();
    }
    return undefined;
  };

  const getMaxFireDangerRatingSwatchColour = (layerId: string): string | number | undefined => {
    let swatchVal: string | number | undefined;
    const layerVal = getLayerValue(layerId);
    if (layerVal !== undefined) {
      const theVal = WmsManager.getColour(layerVal, legends.IDZ10136_AUS_AFDRS_max_fdr_SFC.object);
      if (theVal !== null) {
        swatchVal = theVal;
      }
    }
    return swatchVal;
  };

  const getSelectedLayerSwatchColour = (selLayer: LayerManager.Layer): string | number | undefined => {
    let swatchVal: string | number | undefined;
    const layerVal = getLayerValue(selLayer.id);
    if (layerVal !== undefined) {
      const theVal = WmsManager.getColour(layerVal, legends[selLayer.id].object);
      if (theVal !== null) {
        swatchVal = theVal;
      }
    }
    return swatchVal;
  };

  const isSingleStepOrDailyLayer = (layerId: string): boolean => {
    const targetLyr = LayerManager.layerData.find((lyr) => lyr.id === layerId);
    return targetLyr?.hoursPerStep === 0 || targetLyr?.hoursPerStep === 24;
  };

  const getUpdateIntervalValue = (layerId: string): string => {
    const targetLyr = LayerManager.layerData.find((lyr) => lyr.id === layerId);
    let intervalVal = '';
    switch (targetLyr?.hoursPerStep) {
      case 1: {
        intervalVal = 'Hourly';
        break;
      }
      case 3: {
        intervalVal = '3-Hourly';
        break;
      }
      case 24: {
        intervalVal = 'Daily';
        break;
      }
      default: {
        intervalVal = 'Undefined';
      }
    }
    return intervalVal;
  };

  const getPrimaryDateValue = (layerId: string, date: Date | null | undefined): string => {
    if (!date) {
      return '';
    }
    const targetLyr = LayerManager.layerData.find((lyr) => lyr.id === layerId);
    let primDateText = '';
    if (targetLyr?.hoursPerStep === 1 || targetLyr?.hoursPerStep === 3) {
      primDateText = toTimeHHMMFormat(date);
    } else if (targetLyr?.hoursPerStep === 24) {
      primDateText = toTimeFormatDateOrdinalMMM(date);
    } else {
      primDateText = toTimeDDMonYearFormat(date);
    }
    return primDateText;
  };

  const getSecondaryDateValue = (layerId: string, date: Date | null | undefined): string => {
    if (!date) {
      return '';
    }

    const targetLyr = LayerManager.layerData.find((lyr) => lyr.id === layerId);
    let secDateText = '';
    if (targetLyr?.hoursPerStep === 1 || targetLyr?.hoursPerStep === 3) {
      secDateText = toTimeFormatDateOrdinalMMM(date);
    } else {
      secDateText = toTimeDDMonYearFormat(date);
    }
    return secDateText;
  };

  if (selectedLayer && selectedLayer.name === 'Fuel Type' && data?.[selectedLayer.id] != null) {
    selectedLayerValue = fuelTypeModel?.Fuel_FDR ? fuelTypeModel?.Fuel_FDR : '';
  } else if (selectedLayer && selectedLayer.name === 'Grass Fuel Condition' && data?.[selectedLayer.id] != null) {
    selectedLayerValue = LocationManager.fuelConditionText(+data?.[selectedLayer.id]);
  } else if (
    selectedLayer &&
    selectedLayer.name.indexOf('Fire Danger Rating') !== -1 &&
    data?.[selectedLayer.id] != null
  ) {
    selectedLayerValue = ratingToName(+data[selectedLayer.id]);
  } else if (selectedLayer && (data?.[selectedLayer.id] == null || Number.isNaN(data?.[selectedLayer.id]))) {
    if (Number.isNaN(data?.[selectedLayer.id])) {
      selectedLayerValue = 'NaN';
    } else {
      selectedLayerValue = 'N/A';
    }
  } else if (selectedLayer && data?.[selectedLayer.id] != null) {
    selectedLayerValue +=
      selectedLayer?.precision != null
        ? (+data[selectedLayer.id]).toFixed(selectedLayer.precision)
        : data[selectedLayer.id];
    if (selectedLayer.units) selectedLayerValue += selectedLayer.units;
  }

  function toggleSubfuelDesc() {
    console.log(fuelTypeModel);
    if (!showSubFuelDesc) {
      // showSubFuelDesc = true;
      setShowSubFuelDesc(true);
    } else {
      // showSubFuelDesc = false;
      setShowSubFuelDesc(false);
    }
  }

  function closeStateChange() {
    setShowSubFuelDesc(false);
    close();
  }

  return (
    <Paper className={classes.root}>
      {/* Line 1. AF-934
      Show the selected layer name */}
      <Typography className={classes.title} variant="subtitle1">
        {selectedLayer?.name || 'No Selected Detected!'}
      </Typography>

      {/* Line 2. AF-934
      If swatch layer show:
        swatch+value                time
      Else
        numericValue
      */}

      <div className={classes.leftLeft}>
        {selectedLayer && data !== null ? (
          <>
            {
              // Layer colour swatch
              getSelectedLayerSwatchColour(selectedLayer) ? (
                <RouterLink
                  to={`/ratings/${((data?.Jurisdiction as string) ?? '').toLowerCase()}?search=${
                    data?.FireWeatherAreas ?? ''
                  }`}
                >
                  <div
                    className={classes.legendSwatch}
                    style={{
                      background: getSelectedLayerSwatchColour(selectedLayer),
                    }}
                  />
                </RouterLink>
              ) : (
                <div />
              )
            }

            {/* Where the name contains 'Fire Danger Rating' show the link
            to the ratings, otherwise just show the heading */}
            {selectedLayer && selectedLayer.name.indexOf('Fire Danger Rating') !== -1 ? (
              <RouterLink
                to={`/ratings/${((data?.Jurisdiction as string) ?? '').toLowerCase()}?search=${
                  data?.FireWeatherAreas ?? ''
                }`}
              >
                <Typography variant="subtitle1" className={classes.subtitle}>
                  {selectedLayerValue}
                </Typography>
              </RouterLink>
            ) : (
              <Typography variant="subtitle1" className={classes.subtitle}>
                {selectedLayerValue}
              </Typography>
            )}
          </>
        ) : (
          <div />
        )}

        {selectedDate && selectedLayer && (
          <Typography align="right" variant="subtitle1" className={classes.subtitle}>
            {getPrimaryDateValue(selectedLayer?.id, selectedDate)}
          </Typography>
        )}
      </div>

      {/* Line 3. AF-934

      If !== single time step layer (e.g. TSF) show:
        Update Interval:            Hourly|3-Hourly|Daily etc

      */}

      {selectedLayer && !isSingleStepOrDailyLayer(selectedLayer.id) && (
        <div className={classes.leftRight}>
          <Typography className={classes.white} variant="subtitle2">
            Update Interval:
          </Typography>
          <Typography align="right" className={classes.value} variant="subtitle2">
            {getUpdateIntervalValue(selectedLayer.id)}
          </Typography>
        </div>
      )}

      {/* Line 4. AF-934
      If !== single time step layer (e.g. TSF) show:
        Date:                       dateValue
      */}

      {selectedLayer && !isSingleStepOrDailyLayer(selectedLayer.id) && (
        <div className={classes.leftRight}>
          <Typography className={classes.white} variant="subtitle2">
            Date:
          </Typography>

          <Typography align="right" className={classes.value} variant="subtitle2">
            {getSecondaryDateValue(selectedLayer.id, selectedDate)}
          </Typography>
        </div>
      )}

      {/* Line 5. AF-934

      If !== Max Fire Danger Rating show:
        Max fire danger rating:      swatch+value

      */}

      {selectedLayer && selectedLayer.name !== 'Max Fire Danger Rating' && (
        <div
          className={classes.leftRight}
          style={{
            borderTop: '1px solid black',
            gap: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            paddingTop: theme.spacing(0.5),
          }}
        >
          <Typography className={classes.white} variant="subtitle2">
            Max Fire Danger Rating:
          </Typography>
          {getMaxFireDangerRatingSwatchColour('IDZ10136_AUS_AFDRS_max_fdr_SFC') !== undefined ? (
            <div
              className={classes.legendSwatchSmall}
              style={{
                background: getMaxFireDangerRatingSwatchColour('IDZ10136_AUS_AFDRS_max_fdr_SFC'),
              }}
            />
          ) : (
            <div />
          )}

          <Typography align="right" className={classes.value} variant="subtitle2">
            {selectedDate != null ? ratingToName(+(getLayerValue('IDZ10136_AUS_AFDRS_max_fdr_SFC') ?? 0)) : 'N/A'}
          </Typography>
        </div>
      )}

      <div
        className={classes.leftRight}
        style={{ borderTop: '1px solid black', marginTop: theme.spacing(0.5), paddingTop: theme.spacing(0.5) }}
      >
        <Typography className={classes.white} variant="subtitle2">
          Lon: {coords?.[0].toFixed(4)}
        </Typography>
        <Typography align="right" className={classes.white} variant="subtitle2">
          Lat: {coords?.[1].toFixed(4)}
        </Typography>
      </div>
      {selectedLayer && selectedLayer.name !== 'Fuel Type' && (
        <div className={classes.leftRight}>
          <Typography className={classes.white} variant="subtitle2">
            Fuel Type:
          </Typography>
          <Typography align="right" className={classes.value} variant="subtitle2">
            {fuelTypeModel?.Fuel_FDR}
          </Typography>
        </div>
      )}
      {selectedLayer && (
        <div className={classes.leftRight}>
          <Typography className={classes.white} variant="subtitle2">
            Sub-Fuel Type:
          </Typography>
          <Typography
            align="right"
            className={`${classes.subfuelDropdown} ${classes.value}`}
            variant="subtitle2"
            onClick={toggleSubfuelDesc}
          >
            {!showSubFuelDesc && <ArrowDropDown />}
            {showSubFuelDesc && <ArrowDropUp />}
            {fuelTypeModel?.FTno_State}
          </Typography>
        </div>
      )}
      {showSubFuelDesc && (
        <div className={classes.leftRight}>
          <Typography className={`${classes.white} ${classes.subFuelDescContainer}`} variant="subtitle2">
            <div
              className={classes.leftRight}
              style={{
                borderBottom: '1px solid rgba(255,255,255,0.5)',
                marginBottom: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
              }}
            />
            <div>{fuelTypeModel?.Fuel_Name}</div>
            <div
              className={classes.leftRight}
              style={{
                borderBottom: '1px solid rgba(255,255,255,0.5)',
                marginBottom: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
              }}
            />
          </Typography>
        </div>
      )}
      <div
        className={classes.leftRight}
        style={{ borderBottom: '1px solid black', marginBottom: theme.spacing(0.5), paddingBottom: theme.spacing(0.5) }}
      >
        <Typography className={classes.white} variant="subtitle2">
          Model:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {fuelTypeModel?.FBM}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Temperature:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {data == null ? 'N/A ' : (+data?.IDZ71000_AUS_T_SFC)?.toFixed(layerLookup.IDZ71000_AUS_T_SFC?.precision)}
          {layerLookup.IDZ71000_AUS_T_SFC?.units}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Daily Precipitation (25%):
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {data == null
            ? 'N/A '
            : (+data?.IDZ71014_AUS_DailyPrecip25Pct_SFC)?.toFixed(
                layerLookup.IDZ71014_AUS_DailyPrecip25Pct_SFC?.precision,
              )}
          {layerLookup.IDZ71014_AUS_DailyPrecip25Pct_SFC?.units}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Relative Humidity:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {data == null ? 'N/A ' : (+data?.IDZ71018_AUS_RH_SFC)?.toFixed(layerLookup.IDZ71018_AUS_RH_SFC?.precision)}
          {layerLookup.IDZ71018_AUS_RH_SFC?.units}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Wind Magnitude:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {data == null
            ? 'N/A '
            : (+data?.IDZ71071_AUS_WindMagKmh_SFC)?.toFixed(layerLookup.IDZ71071_AUS_WindMagKmh_SFC?.precision)}
          {layerLookup.IDZ71071_AUS_WindMagKmh_SFC?.units}
        </Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button className={classes.btn} onClick={closeStateChange}>
          <Close /> Close
        </Button>
        {/* <Button className={classes.btn} onClick={close}>
          <Close /> Close
        </Button> */}
        <Button className={classes.btn} onClick={() => onViewDetails && onViewDetails(coords)}>
          View Details
        </Button>
      </div>
    </Paper>
  );
};

WeatherPopup.displayName = 'WeatherPopup';
export default WeatherPopup;
